import React, { useState } from 'react';
import { Row, Col, Divider } from 'antd';
import { Footer } from 'antd/es/layout/layout';
import '../../css/Footer.css';
import '../../css/Common.css';
import logo from '../../images/newshub-logo-main.png';
import { 
  YoutubeOutlined, 
  LinkedinOutlined, 
  TwitterOutlined, 
  EnvironmentOutlined, 
  MailOutlined, 
  CopyrightOutlined
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import TermOfUseModal from './TermOfUseModal';
import PrivacyPoliciesModal from './PrivacyPolicyModal';

const FooterCompoment: React.FC = () => {
  const navigate = useNavigate()
  const [openTermsModal, setTermsModelOpen] = useState(false);
  const [openPrivacyModal, setOpenPrivacyModal] = useState(false)
  

  return (
    <Footer style={{ marginTop: '10px', background: "black" }}>
      <TermOfUseModal isOpen={openTermsModal}
                      setIsOpen={setTermsModelOpen}/>  
      <PrivacyPoliciesModal isOpen = {openPrivacyModal} 
                            setIsOpen = {setOpenPrivacyModal}/>  
      <Row gutter={[16, 16]}>
        <Col flex={1}/>
        <Col flex={2}>
            <h3 className="menu-text" style={{color: "white"}}><strong>GET IN TOUCH</strong></h3>
            <Divider plain/>
            <p className="menu-text"> <a href = "mailto: info@ainewshub.ie" style={{color: "white"}}><MailOutlined/> info@ainewshub.ie</a></p>          
            <p className="menu-text" style={{color: "white"}}>
                <span><EnvironmentOutlined /> CeADAR, Ireland’s Centre for AI,  </span>
                <br/>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;Founders’ District, Clonskeagh, Dublin 4, Ireland</span>
            </p>
        </Col> 
        <Col flex={1}>
              <h3 className="menu-text" style={{color: "white"}}><strong>AI NewsHub</strong></h3>
              <Divider plain/>          
              <ul>
                <li>
                  <a href="/" className="menu-text" style={{color: "white"}}>Home</a>
                </li>
                <li>
                  <a href="/about" className="menu-text" style={{color: "white"}}>About</a>
                </li>
              </ul>
        </Col>
        <Col flex={1}>
              <h3 className="menu-text" style={{color: "white"}}><strong>CeADAR</strong></h3>
              <Divider plain/>
              <ul>
              <li>
                    <a href="https://ceadar.ie/" rel="noreferrer" target="_blank" className="menu-text" style={{color: "white"}}>
                      Home
                    </a>
                </li>
                <li>
                    <a href="https://ceadar.ie/community/" rel="noreferrer" target="_blank" className="menu-text" style={{color: "white"}}>
                      Community
                    </a>
                </li>
                <li>
                  <a onClick={(e) => setOpenPrivacyModal(true)} className="menu-text" style={{color: "white"}}>
                      Privacy Policy
                  </a>
                </li>
                  <li>
                    <a onClick={(e) => setTermsModelOpen(true)} className="menu-text" style={{color: "white"}}>
                      Term of Use
                    </a>
                  </li>

              </ul>
            </Col>
          <Col flex={1}>
            <h3 className="menu-text" style={{color: "white"}}><strong>Socials</strong></h3>
            <Divider plain/>
            <li>
            <a href="https://www.youtube.com/user/CeADARIreland/videos" rel="noreferrer" target="_blank" className="menu-text" style={{color: "white", fontSize: "35px"}}>
                    <YoutubeOutlined /> 
                  </a>
                </li>
                <li>
                <a href="https://twitter.com/ceadarireland" rel="noreferrer" className="menu-text"  target="_blank" style={{color: "white", fontSize: "35px"}}>
                    <svg width="28" height="29" viewBox="0 0 1200 1227" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z" fill="white"/>
                    </svg>
                  </a>
                </li>
                <li>
                <a href="https://www.linkedin.com/company/ceadar-ireland" rel="noreferrer" target="_blank" className="menu-text" style={{color: "white", fontSize: "35px"}}>
                    <LinkedinOutlined />
                  </a>
                </li>

            {/* <Row gutter={16}>
              <Col>
                  <a href="https://www.youtube.com/user/CeADARIreland/videos" rel="noreferrer" target="_blank" className="menu-text" style={{color: "white", fontSize: "35px"}}>
                    <YoutubeOutlined /> 
                  </a>
              </Col>
              <Col>
                  <a href="https://twitter.com/ceadarireland" rel="noreferrer" className="menu-text"  target="_blank" style={{color: "white", fontSize: "35px"}}>
                        <TwitterOutlined /> 
                  </a>
              </Col>
              <Col>
                  <a href="https://www.linkedin.com/company/ceadar-ireland" rel="noreferrer" target="_blank" className="menu-text" style={{color: "white", fontSize: "35px"}}>
                    <LinkedinOutlined />
                  </a>
              </Col>
            </Row> */}
        </Col>
        <Col lg={2}/>
      </Row>
      <Row gutter={[16,16]} className="logo-row">
          <img src={logo} width="200px" onClick={() => navigate('/')} style={{cursor: "pointer"}}/>
      </Row>
      <Row gutter={[16,16]} justify="center" align="middle">
        <h1 className="section-title" style={{color: '#00c1d4', fontSize: '20px', textAlign: 'center'}}>Designed with Responsibility, Attention, and Care</h1>
      </Row>
      <Divider style={{borderColor: "white"}}/>
      <Row gutter={[16,16]}>
        <span style={{color: "white"}}><CopyrightOutlined /> Copyrights AI NewsHub 2023 | All right reserved</span>
      </Row>      
    </Footer>
  );
};

export default FooterCompoment;

