import React from 'react';
import HomePage from './components/main/HomePage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AboutPage from './components/main/About';
import UserProfileCreation from './components/users/profile/UserProfileCreation';
import UserProfileUpdate from './components/users/profile/UserProfileUpdate';
import NewsContent from './components/contents/NewsContent';
import { AuthDataProvider } from './contexts/AuthDataContext';
import ResetPasswordConfirmForm from './components/ResetPasswordConfirmForm';
import './css/App.css'
import CookieConsent from './components/main/CookiesConsent';
import EmailVerification from './components/EmailVerification';
import Personalisation from './components/contents/Personalisation';
import VisualisationPage from './components/vis/VisualisationPage';
import { ThemeProvider } from './contexts/ThemeContext';
import { ThemeSwitch } from './components/ThemeSwitch';
import { FloatButton } from 'antd';
import WhitePaperTab from './components/whitepaper/WhitePaperTab';

const App: React.FC = () => {
  return (
    <>
    <AuthDataProvider>
      <ThemeProvider>
        <Router>
          <FloatButton.Group style={{ right: 24 }}>
            <ThemeSwitch/>
          </FloatButton.Group>
          <Routes>
            <Route path='/' element = {<HomePage/>}/>
            <Route path='/about' element = {<AboutPage/>}/>        
            <Route path='/users/profile/new' element = {<UserProfileCreation/>}/>
            <Route path='/users/profile/update' element = {<UserProfileUpdate/>}/>            
            <Route path='/content/premium/:country' element = {<NewsContent subscribed={true}/>}/>               
            <Route path='/content/basic/:country' element = {<NewsContent subscribed={false}/>}/>
            <Route path='/personal/premium' element = {<Personalisation subscribed={true}/>}/>          
            <Route path='/personal/basic' element = {<Personalisation subscribed={false}/>}/>
            <Route path='/analytics' element = {<VisualisationPage/>}/>
            <Route path='/whitepaper' element = {<WhitePaperTab/>}/>
            <Route path='/password-reset/:token' element = {<ResetPasswordConfirmForm/>}/>
            <Route path='/auth/email/verify' element = {<EmailVerification/>}/>     
          </Routes>
        </Router>
      </ThemeProvider>
    </AuthDataProvider>
    <CookieConsent/>
    </>
  );
};

export default App;
