import { Button, Col, Collapse, Modal, Row, Space} from "antd"
import TagTooltip from "./TagTooltip"
import { useState } from "react";
const {Panel} = Collapse;

interface CardDetailsType {
    onOpen: boolean,
    onOk: () => void,
    onCancel: () => void,
    card: any
}
export const CardDetails: React.FC<CardDetailsType> = ({onOpen, onOk, onCancel, card}) => {
      const [showBiasDetection, setShowBiasDetection] = useState(false);
      const toggleBiasDetection = () => {
        setShowBiasDetection(!showBiasDetection);
      };
      return (
        <Modal title={<strong>{card.title}</strong>}
        open={onOpen} 
        onOk={onOk} 
        onCancel={onCancel}
        footer={null}
        centered
        closable={false}
        >
            <Row style={{paddingBottom: '0.5rem'}}>
              <strong>AI Generated Summary</strong>
            </Row>
            <p>{card.metadata ? (card.metadata.llm__summary__tags ? card.metadata.llm__summary__tags : card.summary) : card.summary}</p>
            {card.metadata && (
              <>
                <Row gutter={[16,16]} style={{marginBottom: "1rem"}}>
                  {card.metadata.llm__bias_detection__tags && (
                    <Col flex={1}>
                      <Collapse activeKey={showBiasDetection ? '1' : '0'} size='small'>
                        <Panel
                            header={
                              <div>
                                  <div onClick={toggleBiasDetection} style={{ cursor: 'pointer', textAlign: 'left'}}>
                                      <strong  style={{ marginRight: 8, color: 'black'}}>
                                          Article Bias Detection
                                      </strong>
                                  </div>
                              </div>
                              } key="1">
                              <p>{card.metadata.llm__bias_detection__tags}</p>
                        </Panel>
                      </Collapse>                
                    </Col>
                  )} 
                </Row>   
                <Row gutter={[16,16]} style={{marginBottom: "1rem"}}>
                    {card.metadata.llm__sentiment__analysis__tags && (
                      <Col flex={1}>
                      <strong>Sentiment: </strong><span>{card.metadata.llm__sentiment__analysis__tags}</span>
                      </Col>
                    )}
                </Row>   
              </>      
            )}
            <Row gutter={[16,16]}>
              <Col flex={1}>
                {card.topics.length > 0 && (<TagTooltip name={card.topics[0].name} 
                                                              desc="Topic" 
                                                              color="#03045e"/>)}
                {card.industry_sectors.length > 0 && (<TagTooltip name={card.industry_sectors[0].name} 
                                                                        desc="Industry Sector"
                                                                        color="#0077b6"/>)}
                {card.publisher.categories.length > 0 && (<TagTooltip name={card.publisher.categories[0].name} 
                                                                    desc="Publisher Category"  
                                                                    color="#00b4d8"/>)}
              </Col>
              <Col flex={0}>
                <Space>
                  <Button type='primary' href={`${card.external_url}`} target="_blank" rel="noreferrer">Read full article</Button>
                  <Button type='primary' onClick={onCancel}>Close</Button>
                </Space>
              </Col>
                
            </Row>          
      </Modal>          
    )
}